import React from "react";
import Layout from '../components/layout';
import {Form, Button} from 'react-bootstrap';

const aboutPage = () => (
    <Layout>
        <h1 style={{textAlign : "center"}}>
            Place an Order/Enquiry
        </h1>
      
        <Form method="POST" data-netlify="true" name="Enquiry">
        <input type="hidden" name="form-name" value="Enquiry" />
            <Form.Group controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter your name" name="Name" required/>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
            <Form.Label>E-Mail</Form.Label>
            <Form.Control type="email" Name="E-mail" placeholder="Enter email" required/>
            </Form.Group>

            <Form.Group controlId="formBasicPhoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control type="number"  name="Phone" placeholder="Enter your phone number" required/>
            </Form.Group>

            <Form.Group controlId="formBasicProduct">
            <Form.Label>Products/Query</Form.Label>
            <Form.Control type="text" name="enquiry type" placeholder="Enter your query" required/>
            </Form.Group>
  
            <Form.Group controlId="formBasicSubject">
            <Form.Label>Subject:</Form.Label>
            <Form.Control as="textarea" name="description" rows="4"/>
            </Form.Group>

            <Button className="bootstrapButton" type="submit">
                Submit
            </Button>
        </Form> 
    </Layout>
)

export default aboutPage;